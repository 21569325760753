// auth.module.js

import { userService } from '../../helpers/authservice/user.service';
import { odooUserService } from "../../helpers/authservice/odoo.auth.service";

const state = {
  status: {},
  user: null,
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedUser: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
};

const actions = {
  async loginOdoo({ dispatch, commit }, { email, password }) {
    commit('loginRequest', { email });

    try {
      const response = await odooUserService.login(email, password);

      if (response.error) {
        commit('loginFailure');
        dispatch('notification/error', response.error, { root: true });
      } else {
        commit('loginSuccess', response.result.token);
      }
    } catch (error) {
      commit('loginFailure');
      dispatch('notification/error', 'An error occurred during login', { root: true });
    }
  },


  

  logout({ commit }) {
    userService.logout();
    commit('logout');
  },

  // Additional actions for registration, fetching user data, etc.
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
};
