import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

export const odoo_url = "http://127.0.0.1:8040"

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem('authUser');
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                // eslint-disable-next-line no-redeclare
                var user = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
                // eslint-disable-next-line no-redeclare
                var user = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    setLoggeedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser'))
            return null;
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        var errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}


import axios from 'axios';

class OdooAuthBackend {
    constructor(apiBaseUrl) {
        this.apiBaseUrl = apiBaseUrl;
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            axios.post(`${this.apiBaseUrl}/register`, { email, password })
                .then(response => resolve(response.data))
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            axios.post(`${this.apiBaseUrl}/api/portal/authenticate`, { email, password })
                .then(response => resolve(response.data))
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            axios.post(`${this.apiBaseUrl}/forget_password`, { email })
                .then(response => resolve(response.data))
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            axios.post(`${this.apiBaseUrl}/logout`)
                .then(response => resolve(response.data))
                .catch(error => reject(this._handleError(error)));
        });
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        let errorMessage = 'An error occurred';
        if (error.response && error.response.data && error.response.data.error) {
            errorMessage = error.response.data.error;
        }
        return errorMessage;
    }
}

let _odooAuthBackend = null;

/**
 * Initilize the backend
 * @param {*} apiBaseUrl 
 */
const initOdooAuthBackend = (apiBaseUrl) => {
    if (!_odooAuthBackend) {
        _odooAuthBackend = new OdooAuthBackend(apiBaseUrl);
    }
    return _odooAuthBackend;
}

/**
 * Returns the Odoo auth backend
 */
const getOdooAuthBackend = () => {
    return _odooAuthBackend;
}


export { initFirebaseBackend, getFirebaseBackend, initOdooAuthBackend, getOdooAuthBackend };