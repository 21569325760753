// store/modules/userProfile.js
import { userProfileService } from "@/helpers/odooService/user_profile.service";

const state = {
    userProfile: {
    },
};
  
const mutations = {
  SET_USER_PROFILE(state, profile) {
    state.userProfile = profile;
  },
  UPDATE_USER_PROFILE_FIELD(state, { field, value }) {
    state.userProfile[field] = value;
  },
  // Add other mutations as needed
};

const actions = {
  setUserProfile({ commit }, profile) {
    commit('SET_USER_PROFILE', profile);
  },
  updateUserProfileField({ commit }, payload) {
    commit('UPDATE_USER_PROFILE_FIELD', payload);
  },
  // Add other actions as needed
};

const getters = {
  userProfile: (state) => state.userProfile,
  // Add other getters as needed
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
  