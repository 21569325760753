import store from '@/state/store'

const mrMenu = [{
    id: 2,
    label: 'Proforma Material Requests',
    icon: 'ti-truck',
    link: '/pages/material-requests'
},
{
    id: 3,
    label: 'Approved Requests',
    icon: 'ti-check',
    link: '/pages/approved-material-requests'
},
{
    id: 4,
    label: 'Material Loadouts',
    icon: 'ti-package',
    link: '/pages/material-request-loadouts'
},
{
    id: 5,
    label: 'Container Loadouts',
    icon: 'ti-clipboard',
    link: '/pages/material-container-manifests'
},
{
    id: 6,
    label: 'Request Exceptions',
    icon: 'ti-close',
    link: '/pages/request-exceptions'
},
]

const deliveryMenu = [
    {
        id: "pending-delivery",
        label: 'Pending Requests',
        icon: 'ti-timer',
        link: '/pages/delivery-material-requests'
    },
    {
        id: "delivered",
        label: 'Delivered Requests',
        icon: 'ti-truck',
        link: '/pages/delivered-material-requests'
    },
    {
        id: "delivery-request-exceptions",
        label: 'Vendor Delivery Request Exceptions',
        icon: 'ti-close',
        link: '/pages/delivery-exception-requests'
    },
]

const manifestMenu = [
    {
        id: "inbound-manifest",
        label: 'Inbound',
        icon: 'ti-arrow-circle-down',
        link: '/pages/cargo-manifests/inbound'
    },
    {
        id: "outbound-manifest",
        label: 'Outbound',
        icon: 'ti-arrow-circle-up',
        link: '/pages/cargo-manifests/outbound'
    }
]


const menuItems = [
    {
        id: "material-request",
        label: 'Material Requests',
        icon: 'ti-package',
        subItems: mrMenu
    },
    {
        id: "vendor-delivery",
        label: 'Vendor Delivery',
        icon: 'ti-truck',
        subItems: deliveryMenu
    },
    {
        id: "manifest",
        label: 'Cargo Manifest',
        icon: 'ti-anchor',
        subItems: manifestMenu // Added subItems for Cargo Manifest
    },
];


const menus = {
    'vendor': deliveryMenu,
    'client': menuItems,
}



export {
    menuItems,
    menus
};