// Odoo User Service
import axios from 'axios';
import { API_BASE_URL } from '../authservice/odoo.auth.service';

export const userProfileService = {
    getUserProfile,
    getCompanyProfileImageUrl,
    requestPasswordReset,  // Add this line
    resetPassword,
};

// Set headers for the preflight (OPTIONS) request
const options = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

async function getUserProfile() {
    try {
        const requestData = new URLSearchParams();
        let jwt = localStorage.getItem('user');
        requestData.append('jwt', jwt);
        const response = await axios.post(`${API_BASE_URL}/api/portal/user_profile`, requestData, options);
        console.log(response);
        return response.data;
    } catch (error) {
        // Handle error
        throw error;
    }
}

async function getCompanyProfileImageUrl(userProfile) {
    return `${API_BASE_URL}/api/portal/company/${userProfile.company_profile_id}/profile_image`;
}

async function requestPasswordReset(email) {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/portal/reset_password_request`, { email }, options);
        return response.data;
    } catch (error) {
        // Handle error
        throw error;
    }
}

async function resetPassword(token, newPassword) {
    try {
        const requestData = new URLSearchParams();
        let jwt = localStorage.getItem('user');
        requestData.append('token', token);
        requestData.append('new_password', newPassword);
        const response = await axios.post(`${API_BASE_URL}/api/portal/reset_password`, requestData, options);
        return response.data;
    } catch (error) {
        throw error;
    }
}

